import { alpha, styled } from '@mui/material';
// ? Material Ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ? Next js
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { API } from '_helpers';

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  padding: theme.spacing(4),
  borderRadius: 10,
  background:
    theme.palette.mode === 'dark'
      ? `${alpha(theme.palette.action.active, 0.02)}`
      : theme.palette.grey.A200,
}));

const Icon = styled(Stack)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Features: React.FC<IProps<IOurTechnologies>> = ({ data }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 12,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? `${alpha(theme.palette.action.active, 0.02)}`
            : theme.palette.grey.A200,
      }}
    >
      <Container>
        <Stack spacing={2} sx={{ px: { xs: 0, md: 12, lg: 20 } }}>
          <Typography
            component="h2"
            color="text.primary"
            variant="h2"
            textAlign="center"
            gutterBottom
          >
            {data.quality.title}
          </Typography>
          <Typography color="text.secondary" textAlign="center">
            {data.quality.description}
          </Typography>
        </Stack>

        <Grid container spacing={4} sx={{ pt: 6, pb: 12 }}>
          {data.quality.cards.map((el, i) => (
            <Grid item xs={12} sm={6} md={4} key={el._id}>
              <Item key={i} elevation={0}>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <Image
                    alt={el.media.alternativeText}
                    src={`${API}${el.media.url}`}
                    width={48}
                    height={48}
                  />
                  <Typography component="h1" variant="h3" gutterBottom>
                    {el.title}
                  </Typography>
                  <Typography
                    component="h1"
                    color="text.secondary"
                    textAlign="center"
                  >
                    {el.description}
                  </Typography>
                </Stack>
              </Item>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4}>
          {data.callToAction.map((el, i) => (
            <Grid item xs={12} md={6} key={el._id}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                alignItems="center"
                sx={{
                  p: 4,
                  borderRadius: 4,
                  bgcolor: (theme) =>
                    `${alpha(
                      i
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,
                      0.1
                    )}`,
                }}
              >
                <Box>
                  <Typography component="h3" variant="h3" gutterBottom>
                    {el.content.title}
                  </Typography>
                  <Typography gutterBottom>{el.content.description}</Typography>
                  <Link href={`${el.button.path}`} passHref>
                    <Button
                      variant="outlined"
                      component="a"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      {el.button.label}
                    </Button>
                  </Link>
                </Box>
                <Box sx={{ minWidth: 150 }}>
                  <Image
                    src={API + el.content.media.url}
                    width={el.content.media.width}
                    height={el.content.media.height}
                    alt={el.content.media.alternativeText}
                    layout="responsive"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
