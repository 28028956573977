import { alpha, styled } from '@mui/material';
// ? Material Ui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import * as React from 'react';
import { API } from '_helpers';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flexGrow: 1, my: { xs: 4, md: 6 } }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTab = styled(Tab)(({ theme }) => ({
  marginRight: theme.spacing(1),
  textTransform: 'none',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.1),
    borderBottom: 'none',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

interface ITechList {
  title: string;
  technologies: ITechnology[];
}

const TechList: React.FC<IProps<ITechList>> = ({ data }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        py: 12,
      }}
    >
      <Container>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing={2}
        >
          <Box
            sx={{
              width: {
                sx: '100%',
                md: '50%',
              },
            }}
          >
            <Typography
              component="h2"
              color="text.primary"
              variant="h2"
              gutterBottom
            >
              {data.title}
            </Typography>
          </Box>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="technologies"
            >
              {data.technologies.map((el, i) => (
                <AntTab key={el._id} label={el.title} {...a11yProps(i)} />
              ))}
            </Tabs>
          </Box>
        </Stack>
        {data.technologies.map((el, i) => (
          <TabPanel key={el._id} value={value} index={i}>
            <Grid container spacing={3}>
              {el.list.map((item, i) => (
                <Grid item xs={6} sm={4} md={3} lg={2} key={item._id}>
                  <Item sx={{ p: 2 }}>
                    <Stack
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        src={`${API}${item.media.url}`}
                        alt={item.media.alternativeText}
                        width={48}
                        height={48}
                      />
                      <Typography variant="h6" color="text.secondary">
                        {item.title}
                      </Typography>
                    </Stack>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        ))}
      </Container>
    </Box>
  );
};

export default TechList;
