import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import config from 'lib/particles.json';
import { useDarkMode } from 'next-dark-mode';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import Particles from 'react-tsparticles';
import { ISourceOptions } from 'tsparticles';
import { API } from '_helpers';

const FeatureIntro: React.FC<IProps<IOurTechnologies>> = ({ data }) => {
  const { darkModeActive } = useDarkMode();
  const router = useRouter();
  const handleClick = (url: string) => {
    router.push(url);
  };
  return (
    <Box sx={{ mt: 3, py: 6, position: 'relative' }}>
      <Box sx={{ position: 'relative', zIndex: 10 }}>
        <Container>
          <Grid
            container
            spacing={4}
            sx={{ minHeight: '100vh' }}
            alignItems="center"
          >
            <Grid item xs={12} lg={6}>
              <Stack spacing={3}>
                <Typography
                  component="h2"
                  color="#fff"
                  variant="h1"
                  gutterBottom
                >
                  {data.banner.title}
                </Typography>
                <Typography color="#fff" gutterBottom>
                  {data.banner.description}
                </Typography>
                <Box
                  onClick={() => handleClick(`${data.banner.button.path}`)}
                  sx={{
                    background: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,

                    py: 2,
                    px: 4,
                    borderRadius: 12,
                    alignSelf: 'flex-start',
                    cursor: 'pointer',
                    '&:hover': {
                      background: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.secondary.dark
                          : theme.palette.primary.dark,
                    },
                    color: '#fff',
                  }}
                >
                  {data.banner.button.label}
                </Box>
              </Stack>
              <Stack spacing={2} sx={{ my: 4 }}>
                <Typography color="#fff" variant="h6" gutterBottom>
                  {data.banner.techs.title}
                </Typography>
                <Stack direction="row" spacing={2}>
                  {data.banner.techs.medias.map((el) => (
                    <Box key={el._id}>
                      <Image
                        alt={el.alternativeText}
                        src={`${API}${el.url}`}
                        width={64}
                        height={64}
                        priority
                      />
                    </Box>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              alignItems="center"
              sx={{
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '100%',
                  height: '100%',
                  m: 'auto',
                }}
              >
                <Image
                  alt={data.banner.media.alternativeText}
                  src={`${API}${data.banner.media.url}`}
                  width={data.banner.media.width}
                  height={data.banner.media.height}
                  priority
                />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Particles
        options={{
          ...(config as ISourceOptions),
          background: {
            color: {
              value: darkModeActive ? '#000f21' : '#0179bb',
            },
          },
        }}
        style={{ minHeight: '100%', position: 'absolute', top: 0, bottom: 0 }}
      />
    </Box>
  );
};

export default FeatureIntro;
