import CheckIcon from '@mui/icons-material/CheckCircleTwoTone';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import React from 'react';
import { API } from '_helpers';

const Frontend: React.FC<IProps<IOurTechnologies>> = ({ data }) => {
  return (
    <Paper
      sx={{
        py: 12,
      }}
      elevation={0}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Typography component="h2" variant="h2" gutterBottom>
                  {data.mainTech[0].title}
                </Typography>
                <Typography color="text.secondary" gutterBottom>
                  {data.mainTech[0].description}
                </Typography>
              </Stack>
              <Grid container sx={{ my: 2 }}>
                {data.mainTech[0].items.map((el, i) => (
                  <Grid item xs={12} md={6} key={el._id}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                        <CheckIcon color="primary" />
                        <Typography color="text.secondary">
                          {el.item}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: '80%', height: '100%', m: 'auto' }}
            >
              <Image
                alt={data.mainTech[0].media.alternativeText}
                src={API + data.mainTech[0].media.url}
                width={data.mainTech[0].media.width}
                height={data.mainTech[0].media.height}
                priority
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Frontend;
